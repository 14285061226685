import * as React from "react";
import { getUnixTime } from "date-fns";
import useClientSide from "./useClientSide";
import { useAuthUser } from "../components/Session";

export default function useCreateDataPoint({ onSuccess, onError }) {
  const isClient = useClientSide();
  const authUser = useAuthUser();
  const [isLoading, setIsLoading] = React.useState(false);

  const createDataPoint = React.useCallback(
    async ({ collection, type, value, description, note, date }) => {
      if (isClient) {
        setIsLoading(true);
        try {
          const unixTime = getUnixTime(date);
          const newWorkout = {
            collection,
            date: unixTime,
            type,
            value, //: `Day ${day}`,
            description, //: `week-of-${week}`,
            note,
          };

          const result = await fetch(
            "/.netlify/functions/create-user-data-point",
            {
              method: "POST",
              body: JSON.stringify({
                uid: authUser.uid,
                payload: newWorkout,
              }),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          const json = await result.json();
          if (json.status === "succeeded") {
            // need to get the new ID back from the netlify function?
            onSuccess(json.data);
          }
        } catch (err) {
          onError(err);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [isClient, authUser, onError, onSuccess]
  );

  return {
    createDataPoint,
    isLoading,
  };
}
