import React from "react";
import DefaultItem from "./DefaultItem";
import WeeklyWorkoutItem from "./WeeklyWorkoutItem";
import VideoWorkoutItem from "./VideoWorkoutItem";
import CustomWorkoutItem from "./CustomWorkoutItem";

const COMPONENT_TYPE_MAP = {
  weekly_workout: WeeklyWorkoutItem,
  video_collection: VideoWorkoutItem,
  custom: CustomWorkoutItem,
};

export default function Details({ data, onDelete }) {
  return (
    <div className="calendar-selected-detail">
      {data.map((item) => {
        const Component = COMPONENT_TYPE_MAP[item.type];
        if (Component) {
          return (
            <Component key={`workout-${item.id}`} {...{ onDelete, item }} />
          );
        } else {
          return <DefaultItem key={`workout-${item.date}`} item={item} />;
        }
      })}
    </div>
  );
}
