import * as React from "react";
import classNames from "classnames";

export default function PageHeader({
  title,
  breadcrumb,
  styleName = undefined,
}) {
  let content = title;
  if (typeof title === "string") {
    content = <h1 className="header-title">{title}</h1>;
  }

  return (
    <div className={classNames("new-page-header", styleName)}>
      {breadcrumb && <h1>{breadcrumb}</h1>}
      {breadcrumb && <h2>{content}</h2>}
      {!breadcrumb && <>{content}</>}
    </div>
  );
}
