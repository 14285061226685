import React from "react";

import { FaPlusCircle } from "@react-icons/all-files/fa/FaPlusCircle";
import { FaArrowCircleLeft } from "@react-icons/all-files/fa/FaArrowCircleLeft";
import { FaArrowCircleRight } from "@react-icons/all-files/fa/FaArrowCircleRight";

import {
  getDaysInMonth,
  format,
  startOfMonth,
  getUnixTime,
  fromUnixTime,
  endOfMonth,
  isFuture,
  startOfDay,
} from "date-fns";
import { useAuthUser } from "../Session";
import { withFirebase } from "../Firebase";
import Details from "./Details";
import AddWorkoutForm from "./AddWorkoutForm";
import UserHabits from "../UserHabits";

function UserCalendar({ firebase }) {
  const authUser = useAuthUser();
  const [showAddForm, setShowAddForm] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [workoutsByDay, setWorkoutsByDay] = React.useState({});
  const [loaded, setLoaded] = React.useState(false);
  const [goalsLoaded, setGoalsLoaded] = React.useState(false);
  const [completedGoals, setCompletedGoals] = React.useState([]);
  const [unsubscribe, setUnsubscribe] = React.useState();
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const today = currentDate.getDate();
  const [selected, setSelected] = React.useState(today);
  const daysInMonth = getDaysInMonth(currentDate);
  const monthStart = startOfMonth(currentDate);
  const dayOfMonthStart = monthStart.getDay();
  const monthEnd = endOfMonth(currentDate);
  const days = Array(daysInMonth)
    .fill(1)
    .map((x, i) => i + 1);

  const selectedDate = React.useMemo(() => {
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      selected
    );
  }, [selected, currentDate]);

  const futureLock = React.useMemo(() => {
    if (selectedDate) {
      return isFuture(startOfDay(selectedDate));
    }

    // unknown
    return true;
  }, [selectedDate]);

  React.useEffect(() => {
    if (firebase && !loaded) {
      const startDate = getUnixTime(monthStart);
      const endDate = getUnixTime(monthEnd);
      const unsub = firebase
        .workoutsForDateRange(authUser.uid, startDate, endDate)
        .onSnapshot((snapshot) => {
          let dbWorkouts = [];
          let dbWorkoutsByDay = {};

          snapshot.forEach((doc) => {
            const workoutData = doc.data();
            dbWorkouts.push({ ...workoutData, id: doc.id });

            const d = fromUnixTime(workoutData.date);
            const dayKey = d.getDate();

            if (dbWorkoutsByDay[dayKey]) {
              dbWorkoutsByDay[dayKey].push({ ...workoutData, id: doc.id });
            } else {
              dbWorkoutsByDay[dayKey] = [{ ...workoutData, id: doc.id }];
            }
          });
          setWorkoutsByDay(dbWorkoutsByDay);

          setData(
            dbWorkouts.map((workout) => {
              const d = fromUnixTime(workout.date);
              const dayKey = d.getDate();
              return dayKey;
            })
          );
          setLoaded(true);
          setUnsubscribe(unsub);
        });
    }
    return () => unsubscribe;
  }, [firebase, loaded, unsubscribe, authUser, monthStart, monthEnd]);

  React.useEffect(() => {
    if (firebase && !goalsLoaded) {
      firebase
        .goalsForDateRange(
          authUser.uid,
          format(monthStart, "yyyy-MM-dd", new Date()),
          format(monthEnd, "yyyy-MM-dd", new Date())
        )
        .get()
        .then((snapshot) => {
          setGoalsLoaded(true);
          let dbGoals = [];

          snapshot.forEach((doc) => {
            const goalData = doc.data();
            dbGoals.push({ ...goalData, id: doc.id });
          });

          setCompletedGoals(dbGoals.map((x) => x.date));
        });
    }
  }, [firebase, authUser, goalsLoaded, monthStart, monthEnd]);

  const handleDeleteByKey = React.useCallback(
    (dayKey, id) => {
      const newWorkoutData = workoutsByDay[dayKey].filter((x) => x.id !== id);
      let newWorkoutsByDay = null;
      if (newWorkoutData.length === 0) {
        newWorkoutsByDay = { ...workoutsByDay };
        delete newWorkoutsByDay[dayKey];
        setData(data.filter((x) => x !== dayKey));
      } else {
        newWorkoutsByDay = { ...workoutsByDay, [dayKey]: newWorkoutData };
      }
      setWorkoutsByDay(newWorkoutsByDay);
    },
    [workoutsByDay, data]
  );

  const handleChangeMonth = React.useCallback(
    (monthDiff) => {
      setLoaded(false);
      setGoalsLoaded(false);
      setCurrentDate(
        new Date(currentDate.setMonth(currentDate.getMonth() + monthDiff))
      );
    },
    [currentDate]
  );

  const handleSuccess = React.useCallback(
    (payload) => {
      const convertedDate = fromUnixTime(payload.date);
      const dayKey = Number(format(convertedDate, "d", new Date()));

      // parse out the day key
      // add the objet to workoutsByDay[key]
      let newWorkoutsByDay = null;
      if (workoutsByDay[dayKey]) {
        newWorkoutsByDay = { ...workoutsByDay };
        newWorkoutsByDay[dayKey].push(payload);
      } else {
        setData([...data, Number(dayKey)]);
        newWorkoutsByDay = { ...workoutsByDay, [dayKey]: [payload] };
      }

      setWorkoutsByDay(newWorkoutsByDay);
      setShowAddForm(false);
    },
    [workoutsByDay, data]
  );

  const handleGoalUpdate = React.useCallback(
    (date, remove) => {
      console.log("handle goal update", date);
      if (!remove) {
        setCompletedGoals([...completedGoals, date]);
      } else {
        const updatedGoals = [...completedGoals];
        // delete the first one found
        const goalIndex = updatedGoals.findIndex((x) => x === date);
        updatedGoals.splice(goalIndex, 1);
        setCompletedGoals(updatedGoals);
      }
    },
    [completedGoals]
  );

  return (
    <div className="user-calendar">
      <div style={{ paddingRight: "10px", paddingLeft: "10px" }}>
        <div className="month-container">
          <div className="button-container">
            <button
              className="fancy black"
              onClick={() => handleChangeMonth(-1)}
            >
              <FaArrowCircleLeft size={18} color="white" />
            </button>
          </div>
          <div className="month">
            <ul>
              <li>
                <span style={{ fontSize: "20px", fontWeight: 700 }}>
                  {format(currentDate, "MMMM yyyy")}
                </span>
              </li>
            </ul>
          </div>
          <div className="button-container">
            <button
              className="fancy black"
              onClick={() => handleChangeMonth(1)}
            >
              <FaArrowCircleRight size={18} color="white" />
            </button>
          </div>
        </div>

        <ul className="weekdays">
          <li>Su</li>
          <li>Mo</li>
          <li>Tu</li>
          <li>We</li>
          <li>Th</li>
          <li>Fr</li>
          <li>Sa</li>
        </ul>

        <ul className="days">
          {Array(dayOfMonthStart)
            .fill(1)
            .map((x, index) => {
              return <li key={`day-skip-${index}`}>&nbsp;</li>;
            })}
          {days.map((dayNumber, index) => {
            let formattedDateForDay = new Date(currentDate);
            formattedDateForDay.setDate(dayNumber);
            formattedDateForDay = format(
              formattedDateForDay,
              "yyyy-MM-dd",
              new Date()
            );

            let dayClassName = "";
            if (
              dayNumber === today &&
              currentDate.getMonth() === new Date().getMonth() &&
              currentDate.getMonth() !== 1
            ) {
              dayClassName = "active";
            } else {
              // do feb heart stuff
              if (currentDate.getMonth() === 1) {
                if (data.includes(dayNumber)) {
                  dayClassName = "heart";
                }
              } else {
                // do normal other 11 month goal and workout highlighting
                if (
                  data.includes(dayNumber) &&
                  completedGoals.includes(formattedDateForDay)
                ) {
                  dayClassName = "completed";
                } else if (data.includes(dayNumber)) {
                  // do half workout half
                  dayClassName = "completed-workout";
                } else if (completedGoals.includes(formattedDateForDay)) {
                  // highlight goal half
                  dayClassName = "completed-goal";
                }
              }
              if (dayNumber === selected) {
                dayClassName += " selected";
              }
            }

            return (
              // eslint-disable-next-line
              <li
                className={dayClassName}
                key={`day-${dayNumber}`}
                onClick={() => {
                  setSelected(dayNumber);
                }}
              >
                {dayNumber}
              </li>
            );
          })}
        </ul>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span className="white-text">
              {`${format(monthStart, "MMMM")} ${selected}, ${format(
                monthStart,
                "yyyy"
              )}`}
            </span>
            {futureLock === false && (
              <button
                className="fancy gold"
                onClick={() => setShowAddForm(true)}
              >
                <FaPlusCircle size={18} style={{ verticalAlign: "middle" }} />
              </button>
            )}
          </div>
          {!!showAddForm && (
            <AddWorkoutForm
              onCancel={() => setShowAddForm(false)}
              onSuccess={handleSuccess}
              {...{ currentDate, selected }}
            />
          )}
          {!!workoutsByDay[selected] && (
            <Details
              data={workoutsByDay[selected]}
              onDelete={handleDeleteByKey}
            />
          )}
        </div>

        <UserHabits date={selectedDate} onUpdate={handleGoalUpdate} />
      </div>
    </div>
  );
}

export default withFirebase(UserCalendar);
