import React from "react";
import { compose } from "recompose";
import { isMobile, isTablet } from "react-device-detect";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Seo from "../../components/seo";
import {
  withActiveSubscription,
  withAuthorization,
} from "../../components/Session";
import UserCalendar from "../../components/UserCalendar";

const isTouch = isMobile || isTablet;

function BalanceTrackerBase() {
  const desktopStyle = {};
  if (!isTouch) {
    desktopStyle.display = "flex";
    desktopStyle.flexDirection = "column";
    desktopStyle.alignItems = "center";
  }

  return (
    <>
      <Seo
        title="Balance Tracker"
        description={`Monitor your progress via completed workouts, measurements and more!`}
      />
      <div className="balance-tracker-page">
        <PageHeader title={"Balance Tracker"} />
        <div
          style={{
            backgroundColor: "transparent",
            padding: 0,
            paddingBottom: 0,
            ...desktopStyle,
          }}
        >
          <UserCalendar />
        </div>
      </div>
    </>
  );
}

const condition = (authUser) => !!authUser;

const BalanceTrackerPage = compose(
  withAuthorization(condition),
  withActiveSubscription
)(BalanceTrackerBase);

const BalanceTrackerPageFinal = () => (
  <Layout>
    <BalanceTrackerPage />
  </Layout>
);

export default BalanceTrackerPageFinal;
