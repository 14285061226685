import { graphql, useStaticQuery } from "gatsby";

const useVideoCollection = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query TRAINING_VIDEO_TITLES {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: {
            frontmatter: {
              templateKey: { eq: "training-video-post" }
              tags: { eq: "Workouts" }
            }
          }
        ) {
          edges {
            node {
              frontmatter {
                title
              }
            }
          }
        }
      }
    `
  );

  return allMarkdownRemark.edges
    .map((x) => x.node.frontmatter.title)
    .sort(function(a, b) {
      return a.localeCompare(b, undefined, { ignorePunctuation: true });
    });
};

export default useVideoCollection;
