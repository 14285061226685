import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import { FaRegCircle } from "@react-icons/all-files/fa/FaRegCircle";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import {
  addDays,
  differenceInCalendarDays,
  format,
  fromUnixTime,
  getUnixTime,
  isBefore,
  parse,
  startOfDay,
  isFuture,
} from "date-fns";
import { Link } from "gatsby-plugin-modal-routing-3";
import { useAuthUser } from "../Session";

// const ALLOWED_IDS = [
//   "QMSCVu9vm8VfcHj8yYQeYuHZ5IK2", // me dev
//   "C49mkwskhiVlwdddYPApHvSvQk03", // me prod
//   "gyWBkE5SXNbx4b7F7dTBdEtflH62", // kristie prod
//   "byroJmtYShhXU0cH2dgDnmPyBUj1", // catherine
//   "10ZkOjxeGJZriWAE3apNZ09QAbT2", // kat
//   "C6iC0lKULKYckrWUKWlQZgAFH682", // rachelle
//   "1CfzhyyJGpVfTGjpKv56ZUcxqdD2", // amanda
//   "L09jhjIOUVeDkXUuhpsCZChI8Cr2", // lisa
// ];

const goals = [
  "H2O",
  "Eat Your Wheaties!",
  "ZZzZZzz",
  "Don't Do Drugs!",
  "Prevent the Binge!",
  "Chill out!",
];

const defaults = {
  goal_last0: 0,
  goal_last1: 0,
  goal_last2: 0,
  goal_last3: 0,
  goal_last4: 0,
  goal_last5: 0,
  goal_count0: 0,
  goal_count1: 0,
  goal_count2: 0,
  goal_count3: 0,
  goal_count4: 0,
  goal_count5: 0,
  goal_start_date: 0,
  goal_lock0: 0,
  goal_lock1: 0,
  goal_lock2: 0,
  goal_lock3: 0,
  goal_lock4: 0,
  goal_lock5: 0,
  goalsCompleted: [],
};

function HabitPillbox({
  title,
  index,
  disabled,
  count,
  last,
  startDate,
  onUpdate,
  parentLoading,
  viewDate,
}) {
  const { uid } = useAuthUser();
  const [loading, setLoading] = React.useState(false);
  const today = new Date();
  const todayFormat = format(today, "yyyy-MM-dd");
  const diffInDays = differenceInCalendarDays(today, fromUnixTime(startDate));
  const total = diffInDays;
  const addedToday = last === viewDate;
  const isViewingToday = viewDate === todayFormat;
  const showTotal = disabled || parentLoading ? false : isViewingToday;
  const disablePress = loading || parentLoading;

  const iconStyle = {
    verticalAlign: "middle",
    cursor: disablePress ? "not-allowed" : "pointer",
    color: "#000",
  };

  const iconSize = 30;
  const icon = addedToday ? (
    <FaCheckCircle
      size={iconSize}
      style={iconStyle}
      onClick={() => {
        if (disablePress === false) {
          submit(true);
        }
      }}
    />
  ) : (
    <FaRegCircle
      // outline={true}
      size={iconSize}
      style={iconStyle}
      onClick={() => {
        if (disablePress === false) {
          submit(false);
        }
      }}
      disabled={loading || parentLoading}
    />
  );

  function submit(remove) {
    if (loading) {
      return;
    }

    setLoading(true);

    const endpoint = remove ? "remove-goal" : "update-goal";
    let unlockNext = false;
    if (!remove && count === 6) {
      unlockNext = true;
    }

    fetch(`/.netlify/functions/${endpoint}`, {
      method: "POST",
      body: JSON.stringify({
        goal: index,
        date: viewDate,
        uid,
        now: todayFormat,
        start_date: startDate,
        unlockNext,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "succeeded") {
          // update parent state
          onUpdate(index, viewDate, remove);

          if (!remove && result.returnData.unlocked) {
            toast.success(
              "Congrats! You've unlocked the next goal. Check back tomorrow to learn about it!",
              {
                position: "bottom-center",
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                closeButton: false,
              }
            );
          }
        }
      })
      .catch((err) => {
        console.log("error submitting goal completion", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div
      className={`pillbox pill${index}`}
      style={{ backgroundColor: "lightgray" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            // cursor: "pointer",
          }}
          // onClick={() => {
          //   if (!disabled) {
          //     navigate(`/basix/${index + 1}/`);
          //   }
          // }}
          // onKeyDown={(e) => {
          //   if (e.key === "Enter" && !disabled) {
          //     navigate(`/basix/${index + 1}/`);
          //   }
          // }}
          role="link"
          tabIndex={0}
        >
          <h4 className="has-text-weight-semibold is-size-4 pill-title">
            <Link to={`/basix/${index + 1}/`} asModal className="black-link">
              <span>{title}</span>
            </Link>
          </h4>
          {showTotal && (
            <span style={{ color: "#000" }}>
              Total {count} / {total}
            </span>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {disabled === false && (
            <div>
              {loading || parentLoading ? (
                <FaSpinner
                  className="fa-spin"
                  size={iconSize}
                  style={{ verticalAlign: "middle", color: "#000" }}
                />
              ) : (
                icon
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default function UserHabits({ date, onUpdate }) {
  const authUser = useAuthUser();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [goalData, setGoalData] = React.useState(defaults);
  const viewDate = format(date || new Date(), "yyyy-MM-dd");
  const [isClient, setClient] = React.useState(false);

  React.useEffect(() => {
    setClient(true);
  }, []);

  React.useEffect(() => {
    function getGoalsForDate() {
      setLoading(true);
      setError(false);
      fetch(
        `/.netlify/functions/get-goals-for-date?time=${getUnixTime(
          new Date()
        )}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            uid: authUser.uid,
            date: viewDate,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "succeeded") {
            setGoalData({
              goal_start_date: authUser.goal_start_date,
              ...data.user,
              goalsCompleted: data.data.map((x) => x.goal),
            });
          }
        })
        .catch((err) => {
          // error
          setError(true);
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (isClient && authUser && authUser.uid) {
      getGoalsForDate();
    }
  }, [viewDate, setLoading, authUser, isClient]);

  function handleUpdate(goal, date, remove) {
    const lastGoalKey = `goal_last${goal}`;
    const countGoalKey = `goal_count${goal}`;
    let newGoalsCompleted = goalData.goalsCompleted;

    if (remove) {
      newGoalsCompleted = newGoalsCompleted.filter((x) => x !== goal);
    } else {
      newGoalsCompleted.push(goal);
    }

    const updatedGoalData = {
      ...goalData,
      [lastGoalKey]: remove ? 0 : date,
      [countGoalKey]: goalData[countGoalKey] + (remove ? -1 : 1),
      goalsCompleted: newGoalsCompleted,
    };
    setGoalData(updatedGoalData);
    onUpdate(date, remove);
  }

  const lockDate = parse(viewDate, "yyyy-MM-dd", new Date());
  const futureLock = isFuture(lockDate);

  // only happens in future
  const goal0Disabled = futureLock;

  // goal_lock# will be 0 initially if the user hasn't unlocked
  // otherwise if it is unlocked, check we're not viewing a date
  // before the goal was unlocked
  const goal1Disabled =
    goalData.goal_lock1 === 0 ||
    futureLock ||
    isBefore(
      lockDate,
      addDays(parse(goalData.goal_lock1, "yyyy-MM-dd", new Date()), 1)
    );

  const goal2Disabled =
    goalData.goal_lock2 === 0 ||
    futureLock ||
    isBefore(
      lockDate,
      addDays(parse(goalData.goal_lock2, "yyyy-MM-dd", new Date()), 1)
    );
  const goal3Disabled =
    goalData.goal_lock3 === 0 ||
    futureLock ||
    isBefore(
      lockDate,
      addDays(parse(goalData.goal_lock3, "yyyy-MM-dd", new Date()), 1)
    );
  const goal4Disabled =
    goalData.goal_lock4 === 0 ||
    futureLock ||
    isBefore(
      lockDate,
      addDays(parse(goalData.goal_lock4, "yyyy-MM-dd", new Date()), 1)
    );
  const goal5Disabled =
    goalData.goal_lock5 === 0 ||
    futureLock ||
    isBefore(
      lockDate,
      addDays(parse(goalData.goal_lock5, "yyyy-MM-dd", new Date()), 1)
    );

  if (
    isClient === false ||
    (authUser && authUser.subscription_status === "canceled") ||
    isBefore(
      date,
      addDays(startOfDay(fromUnixTime(goalData.goal_start_date)), 0)
    )
  ) {
    return null;
  }

  return (
    <div className="user-habits">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!goal0Disabled && (
          <HabitPillbox
            title={goals[0]}
            index={0}
            disabled={error || goal0Disabled}
            count={goalData.goal_count0}
            last={goalData.goalsCompleted.indexOf(0) > -1 ? viewDate : 0}
            startDate={goalData.goal_start_date}
            onUpdate={handleUpdate}
            viewDate={viewDate}
            parentLoading={loading}
          />
        )}
        {!goal1Disabled && (
          <HabitPillbox
            title={goals[1]}
            index={1}
            disabled={error || goal1Disabled}
            count={goalData.goal_count1}
            last={goalData.goalsCompleted.indexOf(1) > -1 ? viewDate : 0}
            startDate={getUnixTime(
              addDays(parse(goalData.goal_lock1, "yyyy-MM-dd", new Date()), 1)
            )}
            onUpdate={handleUpdate}
            viewDate={viewDate}
            parentLoading={loading}
          />
        )}
        {!goal2Disabled && (
          <HabitPillbox
            title={goals[2]}
            index={2}
            disabled={error || goal2Disabled}
            count={goalData.goal_count2}
            last={goalData.goalsCompleted.indexOf(2) > -1 ? viewDate : 0}
            startDate={getUnixTime(
              addDays(parse(goalData.goal_lock2, "yyyy-MM-dd", new Date()), 1)
            )}
            onUpdate={handleUpdate}
            viewDate={viewDate}
            parentLoading={loading}
          />
        )}
        {!goal3Disabled && (
          <HabitPillbox
            title={goals[3]}
            index={3}
            disabled={error || goal3Disabled}
            count={goalData.goal_count3}
            last={goalData.goalsCompleted.indexOf(3) > -1 ? viewDate : 0}
            startDate={getUnixTime(
              addDays(parse(goalData.goal_lock3, "yyyy-MM-dd", new Date()), 1)
            )}
            onUpdate={handleUpdate}
            viewDate={viewDate}
            parentLoading={loading}
          />
        )}
        {!goal4Disabled && (
          <HabitPillbox
            title={goals[4]}
            index={4}
            disabled={error || goal4Disabled}
            count={goalData.goal_count4}
            last={goalData.goalsCompleted.indexOf(4) > -1 ? viewDate : 0}
            startDate={getUnixTime(
              addDays(parse(goalData.goal_lock4, "yyyy-MM-dd", new Date()), 1)
            )}
            onUpdate={handleUpdate}
            viewDate={viewDate}
            parentLoading={loading}
          />
        )}
        {!goal5Disabled && (
          <HabitPillbox
            title={goals[5]}
            index={5}
            disabled={error || goal5Disabled}
            count={goalData.goal_count5}
            last={goalData.goalsCompleted.indexOf(5) > -1 ? viewDate : 0}
            startDate={getUnixTime(
              addDays(parse(goalData.goal_lock5, "yyyy-MM-dd", new Date()), 1)
            )}
            onUpdate={handleUpdate}
            viewDate={viewDate}
            parentLoading={loading}
          />
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
