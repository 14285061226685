import { startOfWeek, isSunday, subWeeks, format } from "date-fns";

const FIVE_PM = 17;

function useCurrentWeek() {
  // date stuff
  const d = new Date();
  d.setHours(FIVE_PM);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);

  const nextWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 7);
  const nextSunday = startOfWeek(nextWeek);
  nextSunday.setHours(FIVE_PM);
  nextSunday.setMinutes(0);
  nextSunday.setSeconds(0);
  nextSunday.setMilliseconds(0);

  const now = new Date();
  const nowHours = now.getHours();

  const isSundayBefore5 = isSunday(d) && nowHours < FIVE_PM;

  const startDate = startOfWeek(now);
  const lastWeekDate = subWeeks(now, 1);

  let thisWeekUri = format(startDate, "yyyy-MM-dd");
  if (isSundayBefore5) {
    thisWeekUri = format(lastWeekDate, "yyyy-MM-dd");
  }

  return thisWeekUri;
}

export default useCurrentWeek;
