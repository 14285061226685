import * as React from "react";
import { useAuthUser } from "../components/Session";
import useCurrentWeek from "../hooks/useCurrentWeek";

export default function useWorkoutCollection(date) {
  const authUser = useAuthUser();
  const [isClient, setClient] = React.useState(false);
  const [listData, setListData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const weekToDisplay = useCurrentWeek();

  React.useEffect(() => {
    setClient(true);
  }, []);

  const fetchWorkoutData = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const result = await fetch(
        "/.netlify/functions/get-workout-titles-for-month",
        {
          method: "POST",
          body: JSON.stringify({
            date: weekToDisplay,
            uid: authUser.uid,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await result.json();
      console.log(json);
      if (json.status === "succeeded") {
        setListData(json.data);
      }
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
  }, [authUser, weekToDisplay]);

  React.useEffect(() => {
    if (isClient) {
      fetchWorkoutData(date);
    }
  }, [isClient, fetchWorkoutData, date]);

  return { data: listData, isLoading };
}
