import React from "react";
import { fromUnixTime } from "date-fns";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import { useAuthUser } from "../Session";

export default function CustomWorkoutItem({ item, onDelete }) {
  const [loading, setLoading] = React.useState(false);
  const authUser = useAuthUser();

  const handleDelete = React.useCallback(
    async (item) => {
      try {
        setLoading(true);
        const response = await fetch(
          "/.netlify/functions/delete-user-data-point",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              uid: authUser.uid,
              key: item.id,
              collection: "workouts",
            }),
          }
        );

        const data = await response.json();
        const dayKey = fromUnixTime(item.date).getDate();
        if (data.status === "succeeded") {
          // delete cached item in the list
          onDelete(dayKey, item.id);
        } else {
          // display error message here
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [authUser, onDelete]
  );

  const Icon = loading ? FaSpinner : FaTrashAlt;

  return (
    <div className={`pillbox`} style={{ backgroundColor: "lightgray" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            cursor: "pointer",
          }}
        >
          <h4 className="has-text-weight-semibold is-size-4 pill-title">
            <span>{item.description}</span>
          </h4>
          <span style={{ color: "#000" }}>{item.value}</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            className={loading ? "fa-spin" : ""}
            size={30}
            style={{
              verticalAlign: "middle",
              cursor: loading ? "not-allowed" : "pointer",
              color: "#000",
            }}
            onClick={() => {
              if (loading === false) {
                handleDelete(item);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
