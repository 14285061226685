import * as React from "react";
import { toast } from "react-toastify";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import { startOfWeek, format } from "date-fns";
import useCreateDataPoint from "../../hooks/useCreateDataPoint";
import useVideoCollection from "../../hooks/useVideoCollection";
import useWorkoutCollection from "../../hooks/useWorkoutCollection";
import useCurrentWeek from "../../hooks/useCurrentWeek";

const WORKOUT_TYPES = {
  weekly_workout: "Weekly Workout",
  video_collection: "Video Collection",
  bike: "Bike",
  walk: "Walk",
  run: "Run",
  yoga: "Yoga",
  other: "Other",
};

const AddWorkoutForm = ({ onCancel, onSuccess, currentDate, selected }) => {
  const onError = React.useCallback((err) => {
    toast.error("Unable to save workout at this time.");
    console.error(err);
  }, []);

  const { isLoading, createDataPoint } = useCreateDataPoint({
    onSuccess,
    onError,
  });

  const [type, setType] = React.useState("none");
  const [workoutType, setWorkoutType] = React.useState("none");
  const [videoType, setVideoType] = React.useState("none");
  const [description, setDescription] = React.useState("");
  const videoCollection = useVideoCollection();
  const { data: workoutCollection } = useWorkoutCollection(currentDate);
  const weekToDisplay = useCurrentWeek();

  const SELECTED_DATE = React.useMemo(() => {
    const date = currentDate;
    date.setDate(selected);

    return date;
  }, [currentDate, selected]);

  const SELECTED_DATE_SUNDAY = React.useMemo(() => {
    return format(startOfWeek(SELECTED_DATE), "yyyy-MM-dd", new Date());
  }, [SELECTED_DATE]);

  const handleAdd = React.useCallback(async () => {
    let payload = null;
    if (type === "weekly_workout") {
      payload = {
        collection: "workouts",
        type,
        date: SELECTED_DATE,
        value: `Day ${workoutType.value}`,
        note: workoutType.label,
        description: `week-of-${SELECTED_DATE_SUNDAY}`,
      };
      console.log({ payload });
    } else if (type === "video_collection") {
      payload = {
        collection: "workouts",
        type,
        date: SELECTED_DATE,
        value: videoType,
        note: videoType,
        description: `video-collection-${videoType}`,
      };
      console.log({ payload });
    } else {
      // custom
      payload = {
        collection: "workouts",
        type: "custom",
        date: SELECTED_DATE,
        value: WORKOUT_TYPES[type],
        note: "",
        description,
      };
    }

    await createDataPoint(payload);
  }, [
    createDataPoint,
    workoutType,
    videoType,
    type,
    description,
    SELECTED_DATE,
    SELECTED_DATE_SUNDAY,
  ]);

  const handleTypeChange = React.useCallback((e) => {
    setType(e.target.value);
  }, []);

  const WEEKLY_WORKOUT_OPTIONS = React.useMemo(() => {
    const workouts = workoutCollection[weekToDisplay];
    if (workouts) {
      return workouts
        .map((item) => ({
          value: item.day,
          label: item.title,
        }))
        .sort((a, b) => a.value - b.value);
    }

    return [];
  }, [workoutCollection, weekToDisplay]);

  const handleWorkoutChange = React.useCallback(
    (e) => {
      const item = WEEKLY_WORKOUT_OPTIONS.find(
        (x) => x.value === Number(e.target.value)
      );
      setWorkoutType(item);
    },
    [WEEKLY_WORKOUT_OPTIONS]
  );

  const handleVideoTypeChange = React.useCallback((e) => {
    setVideoType(e.target.value);
  }, []);

  const handleDescriptionChange = React.useCallback((e) => {
    setDescription(e.target.value);
  }, []);

  const VIDEO_COLLECTION_OPTIONS = videoCollection.map((value) => ({
    value,
  }));

  return (
    <div style={{ marginBottom: "1rem" }}>
      <select
        onChange={handleTypeChange}
        onBlur={handleTypeChange}
        style={{ width: "100%" }}
      >
        <option key={`workout-type-none`} value={"none"}>
          Select type...
        </option>
        {Object.keys(WORKOUT_TYPES).map((key, index) => (
          <option key={`workout-type-${key}`} value={key}>
            {WORKOUT_TYPES[key]}
          </option>
        ))}
      </select>
      {type === "weekly_workout" && (
        <select
          onChange={handleWorkoutChange}
          onBlur={handleWorkoutChange}
          style={{ width: "100%" }}
        >
          <option key={`weekly-workout-type-none`} value={"none"}>
            Select workout...
          </option>
          {WEEKLY_WORKOUT_OPTIONS.map((item) => (
            <option key={`weekly-workout-${item.value}`} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      )}
      {type === "video_collection" && (
        <select
          onChange={handleVideoTypeChange}
          onBlur={handleVideoTypeChange}
          style={{ width: "100%" }}
        >
          <option key={`video-type-none`} value={"none"}>
            Select video...
          </option>
          {VIDEO_COLLECTION_OPTIONS.map((item) => (
            <option key={`video-type-${item.value}`} value={item.value}>
              {item.value}
            </option>
          ))}
        </select>
      )}
      {type !== "weekly_workout" &&
        type !== "video_collection" &&
        type !== "none" && (
          <>
            <input
              type="text"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Description"
              style={{ width: "100%" }}
            />
          </>
        )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button className="fancy gold" onClick={onCancel} disabled={isLoading}>
          Cancel
        </button>
        {isLoading && (
          <FaSpinner
            className="fa-spin"
            size={32}
            color="white"
            style={{ verticalAlign: "middle" }}
          />
        )}
        <button className="fancy gold" onClick={handleAdd} disabled={isLoading}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddWorkoutForm;
